<script setup lang="ts">
import EqZaimIcon from "~/components/icons/eqZaimIcon.vue";

const scrollTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}
</script>

<template>
  <div class="footer-nav">
    <div class="container">
      <a href="#" @click.prevent="scrollTop">
        <EqZaimIcon
            icon-name="arrow-up2"
            class="text-[16px] md:text-[20px] text-secondary-blue-green"
        ></EqZaimIcon>
      </a>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.footer-nav {
  @apply inline-flex absolute -top-[17px] md:-top-[28px] xl:-top-[35px] w-full;
  a {
    @apply bg-white right-0 bottom-0 inline-flex p-8px rounded-full border-secondary-blue-green border-[1px]
    md:p-16px
    xl:p-[24px]
  }
  .container {
    @apply relative max-w-full w-[1512px] mx-auto flex justify-end;
  }
}
</style>
