import type { IRedirect } from "~/composable/redirect.type";

export default defineNuxtRouteMiddleware(async (to, from) => {
  const nuxtApp = useNuxtApp();
  const config = useRuntimeConfig();

  try {
    const data = await $fetch<IRedirect>(
      nuxtApp.ssrContext
        ? `${config.public.admin}/api/redirect`
        : `/api/redirect`,
      {
        query: {
          path: to.path,
        },
      },
    );

    if (data?.data?.url) {
      return navigateTo(data.data.url, {
        redirectCode: data.data.statusCode,
        external: true,
      });
    }
  } catch (e) {
    console.error(e);
  }
});
