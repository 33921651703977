import { default as indexTNebhwOASsMeta } from "/var/www/pages/index.vue?macro=true";
import { default as index34Ym417LR1Meta } from "@/pages/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "folder",
    path: "/:folder*",
    component: () => import("@/pages/index.vue").then(m => m.default || m)
  }
]