import validate from "/var/www/node_modules/nuxt/dist/pages/runtime/validate.js";
import partner_45global from "/var/www/middleware/partner.global.ts";
import redirect_45trailing_45slash_45global from "/var/www/middleware/redirect-trailing-slash.global.ts";
import redirect_45global from "/var/www/middleware/redirect.global.ts";
import manifest_45route_45rule from "/var/www/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  partner_45global,
  redirect_45trailing_45slash_45global,
  redirect_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}