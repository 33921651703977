<script setup lang="ts">
import type { PropType } from "vue";
import type { ISettingsLink, ISettingsText } from "~/composable/setting.type";
import EqZaimIcon from "~/components/icons/eqZaimIcon.vue";
import type { IMenuBlock, IMenuItem } from "~/composable/menu.type";
import { TransitionFade, TransitionSlide } from "@morev/vue-transitions";
import { useMetricsStore } from "~/stores/metrics.store";

const props = defineProps({
  menu: {
    type: Object as PropType<IMenuBlock>,
    optional: true,
    default: () => {},
  },
  logo: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  loginLink: {
    type: Object as PropType<ISettingsLink>,
    loginIcon: true,
    default: () => {},
  },
  loginIcon: {
    type: Object as PropType<ISettingsText>,
    optional: true,
    default: () => {},
  },
  phone: {
    type: Object as PropType<ISettingsLink>,
    optional: true,
    default: () => {},
  },
  phoneNote: {
    type: Object as PropType<ISettingsText>,
    optional: true,
    default: () => {},
  },
  phoneIcon: {
    type: Object as PropType<ISettingsText>,
    optional: true,
    default: () => {},
  },
});

const dropdownVisible = ref(false);

const metricsStore = useMetricsStore();

const route = useRoute();

const clickLkLinkMobile = async (inMenu: boolean) => {
  try {
    await metricsStore.fetchMetrics(
      inMenu ? "LkLinkMobileMenu" : "LkLinkMobile",
    );
  } catch (e) {}
  window.open(props.loginLink?.value.url, props.loginLink?.value.target ?? "");
};

const clickLkLinkDesktop = async () => {
  try {
    await metricsStore.fetchMetrics("LKLinkDesktop");
  } catch (e) {}
  window.open(props.loginLink?.value.url, props.loginLink?.value.target ?? "");
};

const checkDropdownActive = (item: IMenuItem) =>
  item.children
    .map((child) => (child.type === "page" ? child.value.path : ""))
    .indexOf(route.path) !== -1;
</script>

<template>
  <div class="header" :class="{ header_rounded: !dropdownVisible }">
    <div class="container mx-auto">
      <div class="flex justify-between items-center">
        <NuxtLink to="/" class="flex-none">
          <img :src="logo" class="header__logo" :alt="title" />
        </NuxtLink>
        <div>
          <nav class="justify-end hidden xl:flex">
            <template v-for="item in menu.menuContent" :key="item.code">
              <div v-if="!item.children.length" class="header__menu">
                <NuxtLink
                  class="header__menu-link"
                  :to="item.value"
                  active-class="header__menu-link_active"
                >
                  {{ item.title }}
                </NuxtLink>
              </div>

              <div v-if="item.children.length" class="header__menu">
                <div
                  class="header__menu-link"
                  :class="{
                    'header__menu-link_active': checkDropdownActive(item),
                  }"
                  v-text="item.title"
                ></div>
                <div class="header__menu__submenu">
                  <template
                    v-for="subitem in item.children"
                    :key="subitem.code"
                  >
                    <div class="header__menu__submenu-link">
                      <NuxtLink
                        :to="subitem.value"
                        active-class="header__menu__submenu-link_active"
                      >
                        <span>{{ subitem.title }}</span>
                      </NuxtLink>

                      <EqZaimIcon
                        v-if="subitem.options.icon"
                        :icon-name="subitem.options.icon as 'login'"
                        class="text-[20px] header__menu__submenu-icon"
                      />
                    </div>
                  </template>
                </div>
              </div>
            </template>

            <a
              v-if="loginLink && loginIcon"
              :target="loginLink?.value.target ?? ''"
              class="header__menu header__menu-lk flex justify-between gap-8px"
              :href="loginLink?.value.url"
              @click="clickLkLinkDesktop"
            >
              {{ loginLink.value.title }}
              <EqZaimIcon
                :icon-name="loginIcon.value as 'login'"
                class="text-[20px]"
              />
            </a>
          </nav>
          <div class="flex gap-8px md:gap-16px xl:hidden">
            <a
              v-if="loginLink && loginIcon"
              :target="loginLink?.value.target ?? ''"
              :href="loginLink?.value.url ?? ''"
              @click.prevent="clickLkLinkMobile(false)"
              class="flex gap-16px rounded-full p-8px md:p-14px bg-secondary-block-blue"
            >
              <EqZaimIcon
                :icon-name="loginIcon.value as 'login'"
                class="text-[16px] md:text-[20px] text-secondary-blue"
              />
            </a>
            <NuxtLink
              v-if="phone && phoneIcon"
              :to="phone.value.url"
              class="gap-16px rounded-full p-8px md:p-14px bg-secondary-block-blue flex"
            >
              <EqZaimIcon
                :icon-name="phoneIcon.value as 'call'"
                class="text-[16px] md:text-[20px] text-secondary-blue"
              />
            </NuxtLink>
            <a
              href="#"
              class="flex gap-16px rounded-full p-8px md:p-14px bg-secondary-gray-50 relative"
              @click.prevent="dropdownVisible = !dropdownVisible"
            >
              <div class="size-[16px] md:size-[20px]"></div>
              <TransitionFade>
                <EqZaimIcon
                  v-if="dropdownVisible"
                  key="dropdownVisible"
                  icon-name="close-square"
                  class="absolute text-[16px] text-primary-black md:text-[20px]"
                />
                <EqZaimIcon
                  v-else
                  key="dropdownInvisible"
                  icon-name="filter2"
                  class="absolute text-[16px] text-primary-black md:text-[20px]"
                />
              </TransitionFade>
            </a>
          </div>
        </div>
        <TransitionSlide>
          <div v-if="dropdownVisible" class="header__dropdown">
            <div class="header__dropdown-blocks">
              <div class="header__dropdown-block header__dropdown-contacts">
                <div v-if="phone && phoneIcon" class="header__dropdown-contact">
                  <EqZaimIcon
                    :icon-name="phoneIcon.value as 'call'"
                    class="header__dropdown-contact-icon"
                  />
                  <div class="flex flex-col gap-[4px]">
                    <NuxtLink
                      :to="phone.value.url"
                      class="header__dropdown-contact-value"
                    >
                      {{ phone.value.title }}
                    </NuxtLink>
                    <div
                      v-if="phoneNote"
                      class="header__dropdown-contact-note"
                      v-text="phoneNote.value"
                    ></div>
                  </div>
                </div>
              </div>
              <nav class="header__dropdown-block header__dropdown-menu">
                <template v-for="item in menu.menuContent" :key="item.code">
                  <div
                    v-if="!item.children.length"
                    class="header__dropdown-menu__link"
                  >
                    <NuxtLink :to="item.value"> {{ item.title }}</NuxtLink>
                  </div>
                  <div v-if="item.children.length">
                    <template
                      v-for="subitem in item.children"
                      :key="subitem.code"
                    >
                      <div class="header__dropdown-menu__link">
                        <NuxtLink :to="subitem.value">
                          {{ subitem.title }}
                        </NuxtLink>
                      </div>
                    </template>
                  </div>
                </template>
              </nav>
              <div class="header__dropdown-block">
                <div v-if="loginLink && loginIcon" class="header__dropdown__lk">
                  <a
                    :target="loginLink?.value.target ?? ''"
                    :href="loginLink?.value.url"
                    @click.prevent="clickLkLinkMobile(true)"
                  >
                    <EqZaimIcon icon-name="login" class="text-[16px]" />
                    Личный кабинет
                  </a>
                </div>
              </div>
            </div>
          </div>
        </TransitionSlide>
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.header {
  @apply py-8px relative overflow-x-clip
  md:py-[20px];

  &_rounded {
    @apply max-md:rounded-b-block  md:max-xl:rounded-b-form;
  }

  &__logo {
    @apply max-md:w-[120px] max-md:h-[36px] max-xl:w-[171px] max-h-[56px];
  }

  &__menu {
    @apply relative px-16px py-12px transition-all ease-in-out duration-300 text-normal-M-semi cursor-pointer;

    &:hover > &__submenu {
      @apply opacity-100 visible;
    }

    &-link {
      @apply hover:text-secondary-blue-green;

      &_active {
        @apply text-secondary-blue-green;
      }
    }

    &__submenu {
      @apply z-[1] flex flex-col gap-[16px] absolute opacity-0 invisible transition-opacity shadow-default bg-white py-12px px-[20px] rounded-[24px] rounded-tl-none w-[280px] mt-4px left-0;

      &-link {
        @apply flex items-center px-[16px] py-[4px] rounded-[48px]  hover:text-secondary-blue hover:bg-secondary-block-blue;

        &_active {
          @apply text-secondary-blue-green;
        }
      }

      &-icon {
        @apply ml-auto;
      }
    }

    &-lk {
      @apply text-secondary-blue fill-secondary-blue ml-16px flex items-center;
    }
  }

  &__dropdown {
    @apply bg-white shadow-default absolute w-full h-fit left-0 px-24px bottom-0 top-full rounded-b-form py-8px z-[2]
    md:pb-[32px]
    xl:!hidden;

    &-contacts {
      @apply flex flex-col gap-16px;
    }

    &-block {
      @apply flex flex-col border-t-[1px] border-secondary-gray-50 py-16px last:pb-0
      md:py-24px;
    }

    &-contact {
      @apply flex items-center gap-10px py-4px px-16px;

      &-icon {
        @apply text-[20px] text-secondary-blue;
      }

      &-value {
        @apply text-secondary-blue text-title-S;
      }

      &-note {
        @apply text-secondary-gray text-normal-XS;
      }
    }

    &-city {
      @apply flex items-center gap-8px py-14px px-16px;

      &-icon {
        @apply text-[20px] text-secondary-blue;
      }

      &-value {
        @apply text-normal-S
        md:text-normal-M;
      }
    }

    &-menu {
      @apply flex flex-col;

      &__link {
        @apply text-normal-S py-16px px-[36px]
        md:text-normal-M;
      }
    }

    &__lk {
      @apply py-12px px-20px;

      a {
        @apply text-normal-S-semi py-4px flex justify-center items-center w-full gap-10px bg-secondary-block-blue text-secondary-blue rounded-form
        md:text-normal-M-semi;
      }
    }
  }
}
</style>
