<script setup lang="ts">
import type { PropType } from "vue";
import {
  LazyTextParagraph,
  LazyTextHeadersHeader,
  LazyTextRaw,
  LazyTextNestedlist,
  LazyTextImage,
} from "#components";

import type { IText } from "~/composable/text.type";

defineProps({
  items: {
    type: Object as PropType<IText>,
    required: true,
  },
  title: {
    type: String,
    required: true,
    default: "",
  },
  description: {
    type: String,
    default: "",
  },
});

const component = {
  paragraph: LazyTextParagraph,
  raw: LazyTextRaw,
  header: LazyTextHeadersHeader,
  nestedlist: LazyTextNestedlist,
  image: LazyTextImage,
};
</script>

<template>
  <template v-for="(block, index) in items.text.blocks" :key="index">
    <component
      :is="component[block.type as keyof Object]"
      :data="block"
      :level="block.data?.level"
    />
  </template>
</template>
