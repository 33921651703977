<script setup lang="ts">
import type { PropType } from "vue";
import type {
  ISettingsEditorjs,
  ISettingsJson,
  ISettingsLink,
  ISettingsText,
} from "~/composable/setting.type";
import EqZaimIcon from "~/components/icons/eqZaimIcon.vue";
import type { IMenuBlock, IMenuItem } from "~/composable/menu.type";
import { TransitionExpand } from "@morev/vue-transitions";
import Text from "~/components/text/text.vue";
import { useWindowSize } from "@vueuse/core";

const props = defineProps({
  menu: {
    type: Object as PropType<IMenuBlock>,
    optional: true,
    default: () => {},
  },
  logo: {
    type: String,
    required: true,
  },
  logoSmall: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  phone: {
    type: Object as PropType<ISettingsLink>,
    optional: true,
    default: () => {},
  },
  phoneNote: {
    type: Object as PropType<ISettingsText>,
    optional: true,
    default: () => {},
  },
  phoneIcon: {
    type: Object as PropType<ISettingsText>,
    optional: true,
    default: () => {},
  },
  footerInfo: {
    type: Object as PropType<ISettingsEditorjs>,
    optional: true,
    default: () => {},
  },
  footerCookie: {
    type: Object as PropType<ISettingsEditorjs>,
    optional: true,
    default: () => {},
  },
});

const { width } = useWindowSize();

const menuToggle = (item: IMenuItem): void => {
  if (width.value >= 768) return;
  item.open = !item.open;
}
</script>

<template>
  <div class="footer">
    <LayoutNavigation />
    <div class="container mx-auto">
      <div
        class="flex max-md:flex-col md:gap-[20px] md:justify-between pb-24px md:pb-[32px]"
      >
        <div class="footer__top">
          <NuxtLink to="/">
            <img
              class="md:hidden footer__top-logo"
              :src="logoSmall"
              :alt="title"
            />
            <img
              class="max-md:hidden footer__top-logo"
              :src="logo"
              :alt="title"
            />
          </NuxtLink>
          <div v-if="phone && phoneIcon" class="footer__contact">
            <EqZaimIcon
              class="footer__contact-icon"
              :icon-name="phoneIcon.value as 'call'"
            />
            <div class="flex flex-col gap-[4px]">
              <NuxtLink class="footer__contact-value" :to="phone.value.url">
                {{ phone.value.title }}
              </NuxtLink>
              <div
                v-if="phoneNote"
                class="footer__contact-note"
                v-text="phoneNote.value"
              ></div>
            </div>
          </div>
        </div>
        <div class="footer__menu">
          <template v-for="item in menu.menuContent" :key="item.code">
            <div
              v-if="item.children.length"
              class="flex flex-col md:items-center"
            >
              <div
                class="flex justify-between md:mb-[16px] xl:mb-[20px]"
                @click.prevent="menuToggle(item)"
              >
                <div class="footer__menu-link">
                  {{ item.title }}
                  <div
                    class="md:hidden transform transition-transform ml-auto"
                    :class="{ '-rotate-[135deg]': item.open }"
                  >
                    <EqZaimIcon icon-name="arrow-down2" class="text-[16px]" />
                  </div>
                </div>
              </div>
              <TransitionExpand>
                <div
                  v-show="item.open"
                  class="footer__menu-submenu md:!flex md:!opacity-100"
                >
                  <template
                    v-for="subitem in item.children"
                    :key="subitem.code"
                  >
                    <div class="footer__menu-submenu__link">
                      <NuxtLink :to="subitem.value">
                        {{ subitem.title }}</NuxtLink
                      >
                    </div>
                  </template>
                </div>
              </TransitionExpand>
            </div>
          </template>
        </div>
      </div>
      <div class="footer__text">
        <Text
          title=""
          description=""
          :items="{ code: footerInfo.code, text: footerInfo.value }"
        />
      </div>
      <div class="footer__cookie">
        <Text
          title=""
          description=""
          :items="{code: footerCookie.code, text: footerCookie.value}"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.footer {
  @apply bg-secondary-blue-green py-[40px] relative
  md:py-[48px]
  xl:py-[96px];

  &__top {
    @apply flex shrink-0
    max-md:justify-between max-md:items-center max-md:mb-[20px]
    md:flex-col md:gap-8px
    xl:gap-[20px];

    &-logo {
      @apply max-w-[36px] max-h-[40px] object-contain
      md:max-w-[225px] md:max-h-[72px];
    }
  }

  &__menu {
    @apply flex max-md:flex-col max-md:gap-[12px] flex-grow
    md:grid md:grid-flow-col md:justify-end md:gap-[12px]
    md:grid-cols-[repeat(v-bind(menu.menuContent.length),minmax(0,165px))]
    xl:grid-cols-[repeat(v-bind(menu.menuContent.length),minmax(0,265px))]
    xl:gap-[12px];

    &-link {
      @apply text-normal-XS-compact-semi text-white flex w-full cursor-pointer
      md:text-normal-M-semi md:text-center
      xl:text-normal-L-semi;
    }

    &-submenu {
      @apply flex flex-col gap-8px max-md:pt-16px
      xl:gap-[12px];

      &_hide {
        @apply max-md:hidden;
      }

      &__link {
        @apply text-normal-XXS text-state-success
        md:text-normal-S md:text-center;
      }
    }
  }

  &__contact {
    @apply flex items-center gap-10px py-4px max-md:text-right;

    &-icon {
      @apply text-[20px] text-white
      md:text-[24px];
    }

    &-value {
      @apply text-white text-normal-S-bold
      md:text-title-S;
    }

    &-note {
      @apply text-state-success text-normal-XXXS
      md:text-normal-XS;
    }
  }

  &__text {
    @apply text-normal-XXS-compact tracking-[.2px] pt-24px border-t border-t-secondary-block-blue text-state-success
    md:text-normal-XS-compact md:pt-[32px];

    :deep(p + p) {
      @apply mt-16px;
    }

    :deep(a) {
      @apply underline underline-offset-2;
    }
  }

  &__cookie {
    @apply text-normal-XXS-compact mt-16px text-state-success
    md:text-normal-XS-compact;

    :deep(p + p) {
      @apply mt-16px;
    }

    :deep(a) {
      @apply underline underline-offset-2;
    }
  }
}
</style>
