import type {
  ISettingsAction,
  ISettingsGetter,
  ISettingState,
} from "~/stores/settings.store.types";
import type { ISettings } from "~/composable/setting.type";

export const useSettingStore = defineStore<
  string,
  ISettingState,
  ISettingsGetter,
  ISettingsAction
>("settings", {
  state: (): ISettingState => ({
    settings: null,
    comeback: 0,
  }),
  getters: {
    getSettings: (state) => state.settings,
  },
  actions: {
    async fetchSettings() {
      if (this.settings === null) {
        const config = useRuntimeConfig();
        const settings = [
          "favicon",
          "logo",
          "logoSmall",
          "logoFooter",
          "logoSmallFooter",
          "ogImage",
          "loginLink",
          "loginIcon2",
          "phone",
          "phoneNote",
          "email",
          "emailNote",
          "phoneIcon",
          "footerInfo",
          "footerCookie",
          "footerLinks",
          "vitokUrl",
          "gtmId",
          "ymId",
          "meta",
          "calcCard",
        ];
        this.settings = await $fetch<ISettings>(
          `${config.public.admin}/api/settings`,
          {
            query: {
              code: settings.join(","),
            },
          },
        );
      }

      return this.settings;
    },
  },
});
