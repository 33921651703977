import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgo2User, LazySvgo3User, LazySvgoActivity, LazySvgoAddUser, LazySvgoArrowDown, LazySvgoArrowDown2, LazySvgoArrowDown3, LazySvgoArrowDownCircle, LazySvgoArrowDownSquare, LazySvgoArrowLeft, LazySvgoArrowLeft2, LazySvgoArrowLeft3, LazySvgoArrowLeftCircle, LazySvgoArrowLeftSquare, LazySvgoArrowRight, LazySvgoArrowRight2, LazySvgoArrowRight3, LazySvgoArrowRightCircle, LazySvgoArrowRightSquare, LazySvgoArrowUp, LazySvgoArrowUp2, LazySvgoArrowUp3, LazySvgoArrowUpCircle, LazySvgoArrowUpSquare, LazySvgoBag, LazySvgoBag3, LazySvgoBookmark, LazySvgoBuy, LazySvgoCalendar, LazySvgoCall, LazySvgoCallMissed, LazySvgoCallSilent, LazySvgoCalling, LazySvgoCamera, LazySvgoCategory, LazySvgoChart, LazySvgoChat, LazySvgoCloseSquare, LazySvgoDanger, LazySvgoDelete, LazySvgoDiscount, LazySvgoDiscovery, LazySvgoDocument, LazySvgoDownload, LazySvgoDrag, LazySvgoEdit, LazySvgoEditSquare, LazySvgoFilter, LazySvgoFilter2, LazySvgoFilter3, LazySvgoFolder, LazySvgoGame, LazySvgoGraph, LazySvgoHeart, LazySvgoHide, LazySvgoHome, LazySvgoImage, LazySvgoImage3, LazySvgoInfoCircle, LazySvgoInfoSquare, LazySvgoLocation, LazySvgoLock, LazySvgoLogin, LazySvgoLogout, LazySvgoMessage, LazySvgoMoreCircle, LazySvgoMoreSquare, LazySvgoNotification, LazySvgoPaper, LazySvgoPaperDownload, LazySvgoPaperFail, LazySvgoPaperNegative, LazySvgoPaperPlus, LazySvgoPaperUpload, LazySvgoPassword, LazySvgoPlay, LazySvgoPlus, LazySvgoProfile, LazySvgoScan, LazySvgoSearch, LazySvgoSend, LazySvgoSetting, LazySvgoShieldDone, LazySvgoShieldFail, LazySvgoShow, LazySvgoStar, LazySvgoStar2, LazySvgoSwap, LazySvgoTickSquare, LazySvgoTicket, LazySvgoTicketStar, LazySvgoTimeCircle, LazySvgoTimeSquare, LazySvgoUnlock, LazySvgoUpload, LazySvgoVideo, LazySvgoVoice, LazySvgoVoice3, LazySvgoVolumeDown, LazySvgoVolumeOff, LazySvgoVolumeUp, LazySvgoWallet, LazySvgoWork } from '#components'
const lazyGlobalComponents = [
  ["Svgo2User", LazySvgo2User],
["Svgo3User", LazySvgo3User],
["SvgoActivity", LazySvgoActivity],
["SvgoAddUser", LazySvgoAddUser],
["SvgoArrowDown", LazySvgoArrowDown],
["SvgoArrowDown2", LazySvgoArrowDown2],
["SvgoArrowDown3", LazySvgoArrowDown3],
["SvgoArrowDownCircle", LazySvgoArrowDownCircle],
["SvgoArrowDownSquare", LazySvgoArrowDownSquare],
["SvgoArrowLeft", LazySvgoArrowLeft],
["SvgoArrowLeft2", LazySvgoArrowLeft2],
["SvgoArrowLeft3", LazySvgoArrowLeft3],
["SvgoArrowLeftCircle", LazySvgoArrowLeftCircle],
["SvgoArrowLeftSquare", LazySvgoArrowLeftSquare],
["SvgoArrowRight", LazySvgoArrowRight],
["SvgoArrowRight2", LazySvgoArrowRight2],
["SvgoArrowRight3", LazySvgoArrowRight3],
["SvgoArrowRightCircle", LazySvgoArrowRightCircle],
["SvgoArrowRightSquare", LazySvgoArrowRightSquare],
["SvgoArrowUp", LazySvgoArrowUp],
["SvgoArrowUp2", LazySvgoArrowUp2],
["SvgoArrowUp3", LazySvgoArrowUp3],
["SvgoArrowUpCircle", LazySvgoArrowUpCircle],
["SvgoArrowUpSquare", LazySvgoArrowUpSquare],
["SvgoBag", LazySvgoBag],
["SvgoBag3", LazySvgoBag3],
["SvgoBookmark", LazySvgoBookmark],
["SvgoBuy", LazySvgoBuy],
["SvgoCalendar", LazySvgoCalendar],
["SvgoCall", LazySvgoCall],
["SvgoCallMissed", LazySvgoCallMissed],
["SvgoCallSilent", LazySvgoCallSilent],
["SvgoCalling", LazySvgoCalling],
["SvgoCamera", LazySvgoCamera],
["SvgoCategory", LazySvgoCategory],
["SvgoChart", LazySvgoChart],
["SvgoChat", LazySvgoChat],
["SvgoCloseSquare", LazySvgoCloseSquare],
["SvgoDanger", LazySvgoDanger],
["SvgoDelete", LazySvgoDelete],
["SvgoDiscount", LazySvgoDiscount],
["SvgoDiscovery", LazySvgoDiscovery],
["SvgoDocument", LazySvgoDocument],
["SvgoDownload", LazySvgoDownload],
["SvgoDrag", LazySvgoDrag],
["SvgoEdit", LazySvgoEdit],
["SvgoEditSquare", LazySvgoEditSquare],
["SvgoFilter", LazySvgoFilter],
["SvgoFilter2", LazySvgoFilter2],
["SvgoFilter3", LazySvgoFilter3],
["SvgoFolder", LazySvgoFolder],
["SvgoGame", LazySvgoGame],
["SvgoGraph", LazySvgoGraph],
["SvgoHeart", LazySvgoHeart],
["SvgoHide", LazySvgoHide],
["SvgoHome", LazySvgoHome],
["SvgoImage", LazySvgoImage],
["SvgoImage3", LazySvgoImage3],
["SvgoInfoCircle", LazySvgoInfoCircle],
["SvgoInfoSquare", LazySvgoInfoSquare],
["SvgoLocation", LazySvgoLocation],
["SvgoLock", LazySvgoLock],
["SvgoLogin", LazySvgoLogin],
["SvgoLogout", LazySvgoLogout],
["SvgoMessage", LazySvgoMessage],
["SvgoMoreCircle", LazySvgoMoreCircle],
["SvgoMoreSquare", LazySvgoMoreSquare],
["SvgoNotification", LazySvgoNotification],
["SvgoPaper", LazySvgoPaper],
["SvgoPaperDownload", LazySvgoPaperDownload],
["SvgoPaperFail", LazySvgoPaperFail],
["SvgoPaperNegative", LazySvgoPaperNegative],
["SvgoPaperPlus", LazySvgoPaperPlus],
["SvgoPaperUpload", LazySvgoPaperUpload],
["SvgoPassword", LazySvgoPassword],
["SvgoPlay", LazySvgoPlay],
["SvgoPlus", LazySvgoPlus],
["SvgoProfile", LazySvgoProfile],
["SvgoScan", LazySvgoScan],
["SvgoSearch", LazySvgoSearch],
["SvgoSend", LazySvgoSend],
["SvgoSetting", LazySvgoSetting],
["SvgoShieldDone", LazySvgoShieldDone],
["SvgoShieldFail", LazySvgoShieldFail],
["SvgoShow", LazySvgoShow],
["SvgoStar", LazySvgoStar],
["SvgoStar2", LazySvgoStar2],
["SvgoSwap", LazySvgoSwap],
["SvgoTickSquare", LazySvgoTickSquare],
["SvgoTicket", LazySvgoTicket],
["SvgoTicketStar", LazySvgoTicketStar],
["SvgoTimeCircle", LazySvgoTimeCircle],
["SvgoTimeSquare", LazySvgoTimeSquare],
["SvgoUnlock", LazySvgoUnlock],
["SvgoUpload", LazySvgoUpload],
["SvgoVideo", LazySvgoVideo],
["SvgoVoice", LazySvgoVoice],
["SvgoVoice3", LazySvgoVoice3],
["SvgoVolumeDown", LazySvgoVolumeDown],
["SvgoVolumeOff", LazySvgoVolumeOff],
["SvgoVolumeUp", LazySvgoVolumeUp],
["SvgoWallet", LazySvgoWallet],
["SvgoWork", LazySvgoWork],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
