import type {
  IMetricsAction,
  IMetricsGetter,
  IMetricsState,
} from "~/stores/metrics.store.type";

export const useMetricsStore = defineStore<
  string,
  IMetricsState,
  IMetricsGetter,
  IMetricsAction
>("metrics", {
  state: () => ({}),
  getters: {},
  actions: {
    async fetchMetrics(event) {
      return useFetch<{ status: boolean }>(`/api/metrics`, {
        query: { siteEvent: event },
      });
    },
  },
});
