<script setup lang="ts">
import type { ISettings } from "~/composable/setting.type";
import type { IPage } from "~/composable/pageData.types";
import type { IMenu } from "~/composable/menu.type";
import { useMenuStore } from "~/stores/menu.store";
import { useSettingStore } from "~/stores/settings.store";

const menuStore = useMenuStore();
const settingStore = useSettingStore();

const { menu } = storeToRefs(menuStore);
const { settings } = storeToRefs(settingStore);

const error = useError();
const errorText = ref();
const status = ref(error.value.statusCode);
if (error.value) {
  switch (error.value.statusCode) {
    case 404:
      errorText.value = "страница не найдена";
      break;
    default:
      errorText.value = "наверное что-то случилось";
      break;
  }
}

await Promise.all([settingStore.fetchSettings(), menuStore.fetchMenu()]);

if (settings.value) {
  useSeoMeta({
    title: `Что то пошло не так,${errorText.value}`,
    description: "",
    ogTitle: `Что то пошло не так,${errorText.value}`,
    ogDescription: "",
    ogImage: settings.value.data.ogImage.value,
    ogUrl: useRequestURL().toString(),
  });
  useHead({
    htmlAttrs: {
      lang: "ru-RU",
    },
    link: [
      {
        rel: "icon",
        type: "image/x-icon",
        href: settings.value.data.favicon.value,
      },
    ],
  });
}
</script>
<template>
  <div class="flex flex-grow flex-col h-full">
    <header>
      <LayoutHeader
        :menu="menu?.data.header"
        :login-icon="settings?.data.loginIcon2"
        :login-link="settings?.data.loginLink"
        :logo="settings!.data.logo.value"
        :title="errorText"
        :phone="settings!.data.phone"
        :phone-note="settings!.data.phoneNote"
        :phone-icon="settings!.data.phoneIcon"
      />
    </header>
    <div class="container mx-auto flex flex-col gap-5 my-block">
      <h1 class="text-9xl font-bold text-secondary-blue-green">
        {{ status ?? 500 }}
      </h1>
      <div class="text-2xl text-center">
        Что то пошло не так,
        <br />
        {{ errorText }}
      </div>
      <div>
        <a
          href="/"
          class="text-white bg-secondary-blue-green rounded-3xl text-sm px-5 py-2.5 focus:outline-none"
          >На главную</a
        >
      </div>
    </div>
    <footer>
      <LayoutFooter
        :menu="menu?.data.footer"
        :title="errorText"
        :logo="settings!.data.logoFooter?.value ?? settings!.data.logo.value"
        :logo-small="
          settings!.data.logoSmallFooter?.value ??
          settings!.data.logoSmall.value
        "
        :phone="settings!.data.phone"
        :phone-note="settings!.data.phoneNote"
        :phone-icon="settings!.data.phoneIcon"
        :footer-info="settings?.data.footerInfo"
        :footer-cookie="settings?.data.footerCookie"
        :footer-links="settings?.data.footerLinks"
      />
    </footer>
  </div>
</template>
<style scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text-4xl {
  text-align: center;
  margin-bottom: 20px;
}
</style>
