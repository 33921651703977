import { getHeaders, getRequestURL } from "h3";
interface IRef {
  [key: number]: {
    time: number;
    referal: string;
    url: string;
    REF_ID?: unknown;
  };
}

export default defineNuxtRouteMiddleware((to, from) => {
  const nuxtApp = useNuxtApp();
  const event = nuxtApp.ssrContext?.event;
  if (event) {
    const headers = getHeaders(event);
    const url = getRequestURL(event);
    let referer = url;
    let domain = url.host;
    if (domain.endsWith("mmk.local") || domain.endsWith("docker.dev")) {
      let domainArr = domain.split(".");
      domainArr.shift();
      domain = domainArr.join(".");
    }
    if (headers.referer) {
      referer = new URL(headers.referer);
    }
    const date = new Date();
    date.setDate(date.getDate() + 25);
    const refCookie = useCookie("BITRIX_SM_REF_SOURSES", {
      expires: date,
      domain: domain,
    });
    const dateNow = Math.floor(Date.now() / 1000);
    const newCookie: IRef = {
      [dateNow]: {
        time: dateNow,
        referal: referer.toString(),
        url: url.toString().replace("http://", ""),
      },
    };

    if (url.searchParams.has("refid")) {
      const refIdCookie = useCookie("REF_ID", {
        httpOnly: true,
        expires: date,
        domain: domain,
      });
      newCookie[dateNow].REF_ID = url.searchParams.get("refid");
      refIdCookie.value = url.searchParams.get("refid");
    }
    if (url.searchParams.has("utm_source")) {
      refCookie.value = JSON.stringify(newCookie);
    }
  }
});
