import type {
  IMenuAction,
  IMenuGetter,
  IMenuState,
} from "~/stores/menu.store.types";
import type { IMenu } from "~/composable/menu.type";

export const useMenuStore = defineStore<
  string,
  IMenuState,
  IMenuGetter,
  IMenuAction
>("menus", {
  state: () => ({
    menu: null,
  }),
  getters: {
    getMenu: (state) => state.menu,
  },
  actions: {
    async fetchMenu() {
      if (this.menu === null) {
        const config = useRuntimeConfig();
        const data = await $fetch<IMenu>(`${config.public.admin}/api/menu`, {
          query: { code: "header,footer" },
        });
        this.menu = data;
        return this.menu;
      }
      return this.menu;
    },
  },
});
